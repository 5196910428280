import React from "react";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { FiTwitter } from "react-icons/fi";
import { BsInstagram } from "react-icons/bs";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { FaYoutube } from "react-icons/fa";

import { IconButton, Typography, Grid, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import insta from "../assets/Img/insta.png";
import clogo from "../assets/Img/clogo.png";
import playstore from "../assets/Img/playstore.png";
import appstore from "../assets/Img/appstore.png";
import cynetixText from "../assets/Img/CyntixText.png";
import { Link } from "react-router-dom";
export default function Footer(props) {
  const theme = useTheme();
  const islg = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const fontSizeSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const dynamicStyles = {
    ...(matchesSM && {
      width: "100%",
      textAlign: "center",
      alignItems: "center",
    }),
    ...(matchesMD && { justifyContent: "center" }),
    ...(fontSizeSM && { fontSize: "12px" }),
  };
  return (
    <>
      <Grid container className="Footer">
        <Typography
          className="blackcolortypo"
          sx={{ alignSelf: "center", fontSize: "13px", margin: "10px" }}
        ></Typography>

        <Grid
          container
          sx={{ backgroundColor: "#05254C", paddingBottom: "90px" }}
        >
          <Grid
            container
            style={{ justifyContent: "center" }}
            sm={12}
            md={3}
            lg={3}
            sx={{ ...dynamicStyles }}
          >
            <Grid item sx={{ marginTop: "33px" }}>
              <Typography align="center">
                <img src={clogo} className={islg ? "width50" : "width50"} />
              </Typography>
            </Grid>{" "}
          </Grid>
          <Grid container sm={12} md={3} lg={3} sx={{ ...dynamicStyles }}>
            <Grid item>
              <Typography
                className={
                  islg
                    ? "whitecolortypopopins  fs-25px"
                    : "whitecolortypopopins  fs-16px"
                }
                sx={{ marginTop: "33px" }}
              >
                About PsiBorg
              </Typography>
              <Link to="/whycynetix" className="link-style">
                <Typography
                  className="greycolortypo"
                  sx={{ marginTop: "20px" }}
                >
                  Why PsiBorg
                </Typography>
              </Link>
              <Link to="/about" className="link-style">
                <Typography
                  className="greycolortypo"
                  sx={{ marginTop: "20px" }}
                >
                  About PsiBorg
                </Typography>
              </Link>
              <Typography className="greycolortypo" sx={{ marginTop: "20px" }}>
                PsiBorg Mobile Application
              </Typography>
              <Typography className="greycolortypo" sx={{ marginTop: "20px" }}>
                Locate Us
              </Typography>{" "}
            </Grid>
          </Grid>
          <Grid container sm={12} md={3} lg={3} sx={{ ...dynamicStyles }}>
            <Grid item>
              <Link to="/support" className="link-style">
                <Typography
                  className={
                    islg
                      ? "whitecolortypopopins  fs-25px"
                      : "whitecolortypopopins  fs-16px"
                  }
                  sx={{ marginTop: "33px" }}
                >
                  Support
                </Typography>
              </Link>
              <Typography className="greycolortypo" sx={{ marginTop: "20px" }}>
                Help and support ferg
              </Typography>
              <Typography className="greycolortypo" sx={{ marginTop: "20px" }}>
                FAQ
              </Typography>
              <Typography className="greycolortypo" sx={{ marginTop: "20px" }}>
                Terms & Conditions
              </Typography>
              <Link to="/privacypolicy" className="link-style">
                <Typography
                  className="greycolortypo"
                  sx={{ marginTop: "20px" }}
                >
                  Privacy Policy
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid
            container
            direction={"column"}
            sm={12}
            md={3}
            lg={3}
            sx={{ ...dynamicStyles }}
          >
            <Grid item style={{ marginTop: "30px" }}>
              <Typography
                className={
                  islg
                    ? "whitecolortypopopins  fs-25px"
                    : "whitecolortypopopins  fs-16px"
                }
              >
                Connect with us
              </Typography>
              <Grid item className="mt-10">
                <a
                  target="_blank"
                  href="https://www.instagram.com/psiborgindia/"
                >
                  <IconButton>
                    <img src={insta} style={{ height: "37px" }} />
                  </IconButton>
                </a>
                <a target="_blank" href="https://www.facebook.com/psiborgtech">
                  <IconButton className="fbicon footericon">
                    <FaFacebook fontSize={40} />
                  </IconButton>
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/@psiborgtechnologies2701"
                >
                  <IconButton className="youtubeicon footericon">
                    <FaYoutube fontSize={40} />
                  </IconButton>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
