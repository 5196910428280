import React from "react";
import { Typography, Grid, DialogActions, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
//React Icons
import { IoIosArrowForward } from "react-icons/io";
//core components
import BedRoomImg from "../../../assets/Img/BedroomImg.png";
// import Common from "./DialogMediaQuery";

export default function BRTab(props) {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const { TabNext } = props;
  return (
    <>
      <Grid container className="whycynetixgridnoborder roomcontainer">
        <Grid
          item
          sm={12}
          md={5}
          lg={5}
          className={
            isMd ? "dialoglistitemstylemd " : "  dialoglistitemstylesm"
          }
        >
          <Grid item className="itemlist ">
            <Typography
              className={
                isMd
                  ? "bluecolortypopopins  fs-16px animate__animated    animate__backInDown  animate__animated  animate__fadeInLeftBig"
                  : "bluecolortypopopins  fs-11px animate__animated   animate__backInDown  animate__animated  animate__fadeInLeftBig"
              }
            >
              Automate Bedroom.
            </Typography>
            <ul>
              <li>
                <Typography
                  className={
                    isMd
                      ? "bluecolortypopopins  fs-16px animate__animated    animate__backInDown  animate__animated  animate__fadeInLeftBig"
                      : "bluecolortypopopins  fs-11px animate__animated   animate__backInDown  animate__animated  animate__fadeInLeftBig"
                  }
                >
                  Schedule TV on, lights off in one click
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    isMd
                      ? "bluecolortypopopins  fs-16px animate__animated    animate__backInDown  animate__animated  animate__fadeInLeftBig"
                      : "bluecolortypopopins  fs-11px animate__animated   animate__backInDown  animate__animated  animate__fadeInLeftBig"
                  }
                >
                  Control Fan
                </Typography>
              </li>
              <li>
                <Typography
                  className={
                    isMd
                      ? "bluecolortypopopins  fs-16px animate__animated    animate__backInDown  animate__animated  animate__fadeInLeftBig"
                      : "bluecolortypopopins  fs-11px animate__animated   animate__backInDown  animate__animated  animate__fadeInLeftBig"
                  }
                >
                  Turn On/Off Lamp,geyser Music System etc.
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          md={7}
          lg={7}
          className={
            isMd
              ? "dynamicsdialogimgmd animate__backInDown  animate__animated  animate__fadeInLeftBig "
              : "dynamicsdialogimgsm animate__backInDown  animate__animated  animate__fadeInLeftBig"
          }
        >
          <img src={BedRoomImg} />
        </Grid>
        <Typography
          align="center"
          className={
            isMd
              ? "bluecolortypopopins  fs-16px animate__animated    animate__backInDown  animate__animated  animate__fadeInLeftBig"
              : "bluecolortypopopins  fs-11px animate__animated   animate__backInDown  animate__animated  animate__fadeInLeftBig"
          }
        >
          The bedroom is all that you think of after a heavy day at work.
          Imagine all lights, the AC and the music turned on as you step inside
          just with a click. Rest at your best.
        </Typography>
        <DialogActions className="dialog-action-pre">
          <Button className="whitebutton NextButton" onClick={TabNext}>
            <IoIosArrowForward className="ArrowIconWhite" />
          </Button>
        </DialogActions>
      </Grid>
    </>
  );
}
