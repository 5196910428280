import React from "react";
import { Typography, Grid, DialogActions, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
//React Icons
import { IoIosArrowBack } from "react-icons/io";
//core components
import KichenImg from "../../../assets/Img/KitchenImg.png";

export default function BRTab(props) {
  const { TabPre3 } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Grid container className="whycynetixgridnoborder roomcontainer">
        {" "}
        <Grid
          item
          sm={12}
          md={7}
          lg={7}
          className={
            isMd
              ? "dynamicsdialogimgmd  animate__animated     animate__fadeInLeftBig"
              : "dynamicsdialogimgsm animate__animated     animate__fadeInLeftBig"
          }
        >
          <img src={KichenImg} />
        </Grid>
        <Grid
          item
          sm={12}
          md={5}
          lg={5}
          className={
            isMd ? "dialoglistitemstylemd " : "  dialoglistitemstylesm"
          }
        >
          <Grid
            item
            className="itemlist animate__animated     animate__fadeInLeftBig"
          >
            <Typography
              className={
                isMd
                  ? "bluecolortypopopins  fs-16px     animate__animated  animate__fadeInLeftBig"
                  : "bluecolortypopopins  fs-11px    animate__animated  animate__fadeInLeftBig"
              }
            >
              Automate Kitchen
            </Typography>
            <ul>
              <li className="animate__animated     animate__fadeInLeftBig">
                <Typography
                  className={
                    isMd
                      ? "bluecolortypopopins  fs-16px      animate__animated  animate__fadeInLeftBig"
                      : "bluecolortypopopins  fs-11px     animate__animated  animate__fadeInLeftBig"
                  }
                >
                  Control Lights
                </Typography>
              </li>
              <li className="animate__animated     animate__fadeInLeftBig">
                <Typography
                  className={
                    isMd
                      ? "bluecolortypopopins  fs-16px      animate__animated  animate__fadeInLeftBig"
                      : "bluecolortypopopins  fs-11px     animate__animated  animate__fadeInLeftBig"
                  }
                >
                  Turn On/Off Fridge
                </Typography>
              </li>
              <li className="animate__animated     animate__fadeInLeftBig">
                <Typography
                  className={
                    isMd
                      ? "bluecolortypopopins  fs-16px      animate__animated  animate__fadeInLeftBig"
                      : "bluecolortypopopins  fs-11px     animate__animated  animate__fadeInLeftBig"
                  }
                >
                  {" "}
                  Turn On/Off microwave
                </Typography>
              </li>
              <li className="animate__animated     animate__fadeInLeftBig">
                <Typography
                  className={
                    isMd
                      ? "bluecolortypopopins  fs-16px      animate__animated  animate__fadeInLeftBig"
                      : "bluecolortypopopins  fs-11px     animate__animated  animate__fadeInLeftBig"
                  }
                >
                  Turn On/Off Chimney
                </Typography>
              </li>
              <li className="animate__animated     animate__fadeInLeftBig">
                <Typography
                  className={
                    isMd
                      ? "bluecolortypopopins  fs-16px      animate__animated  animate__fadeInLeftBig"
                      : "bluecolortypopopins  fs-11px     animate__animated  animate__fadeInLeftBig"
                  }
                >
                  Turn On/Off Exhaust
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Typography
          align="center"
          className={
            isMd
              ? "bluecolortypopopins  fs-16px      animate__animated  animate__fadeInLeftBig width-100 mt-10"
              : "bluecolortypopopins  fs-11px     animate__animated  animate__fadeInLeftBig width-100 mt-10"
          }
        >
          Your kitchen is where the heart and most appliances are. You can't do
          without your refrigerator, microwave, mixer/grinder, oven and other
          devices. And now, you can smartly use all your appliances while saving
          on unnecessary costs with an automated kitchen.
        </Typography>
        <DialogActions className="dialog-action">
          <Button className="whitebutton NextButton" onClick={TabPre3}>
            <IoIosArrowBack className="ArrowIconWhite" />
          </Button>
        </DialogActions>
      </Grid>
    </>
  );
}
