import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import routes from "./routes";
import "./assets/css/GameApp.css";
import Appbar from "./components/Header";
import Footer from "./components/Footer";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Zoom from "@mui/material/Zoom";
import Home from "./pages/Home/Home";
function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
function App(props) {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout + prop.link == "//") {
        return <Route path={"/"} element={<Navigate to={Home} />} key={key} />;
      } else if (prop.layout === "/") {
        return (
          <Route
            path={
              //prop.layout + prop.link == "//" ? "/" : prop.layout + prop.link
              prop.layout + prop.link
            }
            element={prop.Element}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="container">
        {" "}
        <Toolbar
          id="back-to-top-anchor"
          style={{ minHeight: "0px", size: "20px" }}
        />
        <Appbar />
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/" element={<Navigate to={Home} />} />
        </Routes>
        <Footer />
        <ScrollTop {...props}>
          <Fab
            className="scrollicon"
            size="large"
            aria-label="scroll back to top"
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </div>
    </>
  );
}

export default App;
