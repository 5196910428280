// import { AiOutlineHome } from "react-icons/ai";
import Home from "./pages/Home/Home";
import Products from "./pages/Products/products.js";
import Whycynetix from "./pages/WhyCynetix/whycynetix";
import Support from "./pages/Support/support.js";
import Contact from "./pages/Contact/contact.js";
import About from "./pages/About/about.js";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
const routes = [
  {
    invisible: false,
    name: "Home",
    link: "",
    layout: "/",
    Element: <Home />,
  },
  {
    invisible: false,
    name: "Products",
    link: "products",
    layout: "/",
    Element: <Products />,
  },
  {
    invisible: false,
    name: "Why PsiBorg",
    link: "whypsiborg",
    layout: "/",
    Element: <Whycynetix />,
  },
  {
    invisible: false,
    name: "Support",
    link: "support",
    layout: "/",
    Element: <Support />,
  },
  {
    invisible: false,
    name: "About",
    link: "about",
    layout: "/",
    Element: <About />,
  },
  {
    invisible: false,
    name: "Contact",
    link: "contact",
    layout: "/",
    Element: <Contact />,
  },
  {
    invisible: false,
    link: "privacypolicy",
    layout: "/",
    Element: <PrivacyPolicy />,
  },
];

export default routes;
