import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import supportimg from "../../assets/Img/supportImg.png";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
export default function Supoort() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Grid container className="chsgrid">
        <Typography
          className="bluecolortypopopins  width-100 ls-10 p-t60"
          align="center"
          style={{
            fontSize: "24px",
          }}
        >
          Customer Help & Support
        </Typography>
        <Grid item className="mt-100  width-50">
          <Typography align="center" className="width-100">
            <img src={supportimg} />
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="whycynetixgrid" sm={12}>
        <Typography
          align="center"
          className="bluecolortypopopins width-100  padding-30"
          style={{
            fontSize: "21px",
          }}
        >
          Explore FAQ
        </Typography>
        <Grid container className="p-b60">
          <Grid container className="width-70 p-b20">
            <Grid item sm={12} md={6} lg={6}>
              <Accordion
                className="ArrowIcon accordianstyle"
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="bluecolortypopopins">
                    App Available on playstore?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="skytypo700">
                  Yes, PsiBorg's App is available on both Playstore as well as
                  App store
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item sm={12} md={6} lg={6}>
              <Accordion
                className="ArrowIcon accordianstyle"
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="bluecolortypopopins">
                    Is it compatible with Alexa?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="skytypo700">
                  Yes, PsiBorg's App is compatible with both ok google and
                  alexa. Go to “profile {`->`} Voice assistant {`->`} Alexa
                  {`->`} Link” and follow on screen instructions.
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <Grid container className="width-70 ">
            <Grid item sm={12} md={6} lg={6}>
              <Accordion
                className="ArrowIcon accordianstyle"
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="bluecolortypopopins">
                    How to connect devices?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="skytypo700">
                  Download the PsiBorg's app (available on both ioS and android
                  app store (link)) {` >`} create a user account{` >`} create a
                  home
                  {` >`} click on the ‘+’ icon on the Screen {` >`} select your
                  PsiBorg board {` >`} Follow the instructions on the screen
                  {` >`} your PsiBorg's device is connected
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item sm={12} md={6} lg={6}>
              <Accordion
                className="ArrowIcon accordianstyle"
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="bluecolortypopopins">
                    How to add appliances?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="skytypo700">
                  Open Room {`->`} Select Board {`->`} Select Button {`->`}
                  Select Appliance that you connected in real board.
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
