import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Input,
  TextareaAutosize,
  Button,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import location from "../../assets/Img/locationImg.png";

export default function Contact() {
  const theme = useTheme();
  const LocImageSM = useMediaQuery(theme.breakpoints.down("sm"));
  const dynamicLocImageSM = {
    ...(LocImageSM && { display: "none" }),
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Grid container className="m-50">
        <Typography
          className="bluecolortypo ls-6  width-100 "
          align="center"
          style={{ fontSize: "19px" }}
        >
          Reach out to us !
        </Typography>{" "}
      </Grid>
      <Grid container className="backGcolor">
        <Grid container className="p-l30">
          <Grid item sm={12} lg={3} md={3} className="p-t40 backGcolor">
            <Typography className="skybluetypo700" style={{ fontSize: "36px" }}>
              Contact Us
            </Typography>

            <Input
              placeholder="Enter your Name"
              disableUnderline
              className="inputstyle"
            />
            <Input
              placeholder="Enter your Email"
              disableUnderline
              className="inputstyle"
            />
            <TextareaAutosize
              className="textarea"
              aria-label="minimum height"
              minRows={3}
              disableUnderline
              placeholder="Enter your Requirement"
            />
            <Button className="wcsubmitButton">Submit</Button>
          </Grid>
          <Grid
            item
            sm={12}
            lg={3}
            md={3}
            className="wccallusgrid padding-30  m30-0 "
          >
            <Grid item>
              <Typography
                className="whitecolortypo1"
                style={{ fontSize: "20px" }}
              >
                Address
              </Typography>
              <Typography className="bluecolortypo p-t10">
                India Office: A- 57, Fifth Floor, Sector 136 Noida,
                <Typography className="bluecolortypo">UP- 201304</Typography>
                <Typography className="bluecolortypo">India</Typography>
              </Typography>
            </Grid>
            <Grid item className="">
              <Typography
                className="whitecolortypo1 p-t30"
                style={{ fontSize: "20px" }}
              >
                Call us
              </Typography>
              <Typography className="bluecolortypo p-t10">
                +91-9313785560
                {/* <Typography className="bluecolortypo">+91-989898989</Typography> */}
                {/* <Typography className="bluecolortypo">+91-989898989</Typography> */}
              </Typography>
            </Grid>
            {/* <Grid item className=" p-t30">
              <Typography
                className="whitecolortypo1"
                style={{ fontSize: "20px" }}
              >
                Opening Hours
              </Typography>
              <Typography className="bluecolortypo p-t10">
                Loreum ipsum loreum ipsum
                <Typography className="bluecolortypo">
                  Loreum ipsum loreum ipsum
                </Typography>
              </Typography>
            </Grid> */}
          </Grid>
          <Grid
            item
            sm={false}
            lg={6}
            md={6}
            sx={{
              ...dynamicLocImageSM,
              paddingTop: "40px",
              backgroundColor: "#ffffff",
            }}
          >
            <Typography align="center">
              <img src={location} style={{ height: "600px", width: "600px" }} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
