import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Input,
  TextareaAutosize,
  Button,
} from "@mui/material";
import location from "../../assets/Img/clogo.png";

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Grid container className="mb-40 width-70">
        <Typography
          className="bluecolortypo width-100 mt-10 mt-20"
          style={{ fontSize: "19px" }}
        >
          Privacy Policy
        </Typography>
        <Typography
          className="bluecolortypo width-100 mt-10 "
          style={{ fontSize: "19px" }}
        >
          Introduction
        </Typography>
        <Typography className="blackcolortypoCal-400 mt-10  width-100">
          Welcome to cynetix. This Privacy Policy outlines how we collect, use,
          share, and protect your personal information. By using our app, you
          agree to the terms of this Privacy Policy.
        </Typography>
        <Typography
          className="bluecolortypo width-100 mt-10 "
          style={{ fontSize: "19px" }}
        >
          Information We Collect
        </Typography>
        <Typography className="blackcolortypoCal-400">
          <span className="bluecolortypo-bold ">
            User Provided Information:{" "}
          </span>{" "}
          We may collect personal information such as your name, email address,
          and other contact details when you create an account or contact us for
          support.
          <br />
          <span className="bluecolortypo-bold ">Device Information: </span>
          We may collect device-specific information like device type, operating
          system, and unique device identifiers.
          <br />
          <span className="bluecolortypo-bold "> Usage Information: </span> We
          may collect information about how you use our app, including the
          features you interact with and the actions you take.
          <br />
          <span className="bluecolortypo-bold ">
            {" "}
            Location Information:{" "}
          </span>{" "}
          We may collect and process information about your location, including
          precise geolocation data. This data helps us provide location-based
          services within the app.
        </Typography>
        <Typography
          className="bluecolortypo width-100 mt-10 "
          style={{ fontSize: "19px" }}
        >
          How We Use Your Information
        </Typography>
        <Typography className="blackcolortypoCal-400">
          <Typography className="bluecolortypo-bold ">
            We may use your information for purposes including, but not limited
            to:{" "}
          </Typography>
          Providing and improving our app's functionality and user experience.{" "}
          <br />
          Sending you updates, newsletters, and promotional materials. <br />
          Responding to your inquiries and support requests. <br />
          Analyzing usage patterns to enhance app performance. <br />
          Location information is collected solely based on Wi-Fi signals and is
          not used to track your precise physical location.
        </Typography>
        <Typography
          className="bluecolortypo width-100 mt-10 "
          style={{ fontSize: "19px" }}
        >
          Data Sharing and Third Parties
        </Typography>
        <Typography className="blackcolortypoCal-400 mt-5px ">
          <Typography className="bluecolortypo-bold ">
            We may share your information with third parties under the following
            circumstances:
          </Typography>
          With service providers who assist us in app operations. <br /> When
          required by law or to protect our rights, privacy, safety, or
          property. <br />
          In connection with a merger, acquisition, or sale of our company.
        </Typography>
        <Typography
          className="bluecolortypo width-100 mt-10 "
          style={{ fontSize: "19px" }}
        >
          Your Choices
        </Typography>
        <Typography className="blackcolortypoCal-400 mt-5px ">
          You can: <br />
          Update or correct your personal information. <br />
          Opt-out of receiving promotional emails. <br />
          Disable certain app permissions through your device settings.
        </Typography>
        <Typography
          className="bluecolortypo width-100 mt-10 "
          style={{ fontSize: "19px" }}
        >
          Data Security
        </Typography>
        <Typography className="blackcolortypoCal-400 mt-5px ">
          We implement reasonable security measures to protect your personal
          information from unauthorized access or disclosure.
        </Typography>
        <Typography
          className="bluecolortypo width-100 mt-10 "
          style={{ fontSize: "19px" }}
        >
          Children's Privacy
        </Typography>
        <Typography className="blackcolortypoCal-400 mt-5px ">
          Our app is not intended for use by children under the age of 10yrs. We
          do not knowingly collect personal information from children.
        </Typography>{" "}
        <Typography
          className="bluecolortypo width-100 mt-10 "
          style={{ fontSize: "19px" }}
        >
          Changes to this Privacy Policy
        </Typography>
        <Typography className="blackcolortypoCal-400 mt-5px ">
          We may update this Privacy Policy from time to time. The updated
          version will be posted with its effective date.
        </Typography>
        <Typography
          className="bluecolortypo width-100 mt-10 "
          style={{ fontSize: "19px" }}
        >
          Contact Us
        </Typography>
        <Typography className="blackcolortypoCal-400 mt-5px ">
          If you have any questions or concerns about this Privacy Policy,
          please contact us at [+91-9888014100,cynetix.india@gmail.com].
        </Typography>
      </Grid>
    </>
  );
}
