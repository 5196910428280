import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { Fade as Hamburger } from "hamburger-react";
import $ from "jquery";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { BsWhatsapp } from "react-icons/bs";
// core component
import routes from "../routes";

const pages = [
  "Home",
  "Products",
  "Why PsiBorg",
  "Support",
  "About",
  "Contact",
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

export default function ResponsiveAppBar() {
  const theme = useTheme();

  const islg = useMediaQuery(theme.breakpoints.up("md"));
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  const [isOpen, setOpen] = React.useState(false);
  const [isclose, setClose] = React.useState(true);

  const [value, setValue] = React.useState(0);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const closeSideBar = () => {
    setOpen(false);
  };

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const phoneNumber = 8595608045;
  return (
    <AppBar className="appbar" style={{ position: "static" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            className={
              islg
                ? "whitecolortypopopins  fs-20px   ls-6 "
                : "whitecolortypopopins fs-11px  ls-6"
            }
            sx={{ mr: 2, display: { xs: "none", md: "flex", color: "white" } }}
          >
            <Link to="/" className="link-style-color">
              {" "}
              PsiBorg
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            {/* <button
              id="hamburger-menu"
              data-toggle="ham-navigation"
              class="hamburger-menu-button"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <span class="hamburger-menu-button-open"></span>
            </button> */}

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon toggled={isOpen} toggle={setOpen} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {routes.map((route) => {
                if (route.invisible === false) {
                  return (
                    <MenuItem
                      // key={page}
                      component={Link}
                      to={route.layout + route.link}
                      // selected={value === route.activeIndex}
                      onClose={handleCloseNavMenu}
                      onClick={() => {
                        setValue(route.activeIndex);
                        setAnchorElNav(null);
                      }}
                    >
                      <Typography textAlign="center">{route.name}</Typography>
                    </MenuItem>
                  );
                }
              })}
            </Menu>
          </Box>

          <Typography
            variant="h6"
            noWrap
            component="div"
            className={
              islg
                ? "whitecolortypopopins  fs-20px   ls-6 "
                : "whitecolortypopopins fs-11px  ls-6"
            }
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <Link to="/" className="link-style-color">
              {" "}
              PsiBorg
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {routes.map((route) => {
              if (route.invisible === false) {
                return (
                  <MenuItem
                    sx={{ my: 2, color: "white", display: "block" }}
                    // key={page}
                    component={Link}
                    to={route.layout + route.link}
                    // selected={value === route.activeIndex}
                    onClick={() => {
                      setValue(route.activeIndex);
                    }}
                  >
                    <Typography textAlign="center">{route.name}</Typography>
                  </MenuItem>
                );
              }
            })}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
        <a
          href={`https://wa.me/${phoneNumber}`}
          target="_blank"
          rel="noopener noreferrer"
          className={isMd ? "whatsapp-button" : "whatsapp-button-sm"}
        >
          <BsWhatsapp className="whatsapp-icon" />{" "}
          <span className="whatsapp-text">Chat on WhatsApp</span>
        </a>
      </Container>
    </AppBar>
  );
}
