import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import {
  Typography,
  IconButton,
  DialogTitle,
  Grid,
  Backdrop,
} from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

//core Component
import BedRoomTab from "./BedroomTab1";
import KichenTab from "./KitchenTab4";
import LivingTab from "./LivingTab3";
import OfficeTab from "./OfficeTab2";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typography style={{ overflow: "none" }}>{children}</Typography>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            cursor: "pointer",
            position: "absolute",
            right: 8,
            // top: 3,
            color: "#ffffff",
          }}
        >
          <CloseIcon onClick={onClose} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function FullScreenDialog(props) {
  const {
    states: { open, setOpen, value, setValue },
    handleClickOpen,
    handleClickOpen2,
  } = props;
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");

  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function TabNext() {
    setValue(1);
  }
  function TabPre1() {
    setValue(0);
  }
  function TabNext1() {
    setValue(2);
  }
  function TabPre2() {
    setValue(1);
  }
  function TabNext2() {
    setValue(3);
  }
  function TabPre3() {
    setValue(2);
  }
  function TabNext3() {
    setValue(4);
  }
  function TabPre4() {
    setValue(3);
  }
  return (
    <>
      <div className="buttoncontainer">
        <Button
          disableRipple
          className="btn "
          onClick={() => {
            handleClickOpen(props.states.index);
          }}
        >
          {/* {props.buttonName} */}
        </Button>
      </div>
      {/* <Grid container style={{ width: "100%", border: "1px solid red " }}>
        <Grid item sm={12}>
         
        </Grid>
      </Grid> */}
      <Dialog
        className="animate__animated  animate__fadeInLeftBig dilaogcontainer"
        // fullScreen
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box
          className="whycynetixgridnoborder"
          sx={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            overflow: "none",
          }}
        >
          <Tabs
            style={{ minHeight: "40px" }}
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            ></BootstrapDialogTitle>
          </Tabs>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <BedRoomTab TabNext={TabNext} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <OfficeTab
                handleClickOpen2={handleClickOpen2}
                TabPre1={TabPre1}
                TabNext1={TabNext1}
              />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <LivingTab TabPre2={TabPre2} TabNext2={TabNext2} />
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
              <KichenTab TabPre3={TabPre3} />
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Dialog>
    </>
  );
}
