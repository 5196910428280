import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Input,
  TextareaAutosize,
  Button,
} from "@mui/material";
import location from "../../assets/Img/clogo.png";

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Grid container className="whycynetixgrid">
        <Grid item className="width-50 mt-30">
          <Typography align="center" className="width-100">
            <img src={location} className="width-30" />
          </Typography>
          <Typography
            style={{ fontSize: "19px" }}
            className="bluecolortypopopins ls-6  mt-10 width-100 mb-30 "
            align="center"
          >
            Company Tagline
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="mb-40 width-70">
        <Typography
          className="bluecolortypo width-100 mt-10 mt-20"
          style={{ fontSize: "19px" }}
        >
          About PsiBorg
        </Typography>
        <Typography className="blackcolortypoCal mt-10 mt-20 width-100">
          PsiBorg is a leading home automation solutions provider aiming to
          deliver powerful solutions for Smart Homes. We combine cutting-edge
          technology and design thinking in our products so you can have
          user-friendly control over your entire home just with a few taps on
          the screen.
        </Typography>
        <Typography className="blackcolortypoCal">
          Our automation system makes living comfortable by offering seamless
          control of your music, television, lighting, air-conditioning and
          blinds. With a simple interface, you can remotely monitor and control
          your home from wherever you are at any time.
        </Typography>
        <Typography className="blackcolortypoCal">
          We are here with a mission to combine technology, comfort and luxury
          with our reliable solutions.
        </Typography>
        <Typography className="blackcolortypoCal">
          Experience an easy and comfortable life with PsiBorg Home Automation
          solutions. Convert your home into a Smart Home where every switch and
          appliance is a connected device.
        </Typography>
      </Grid>
    </>
  );
}
