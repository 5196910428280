import React, { useRef, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Container,
  breadcrumbsClasses,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

import "animate.css";
// import Card from "./parallax-depth-cards/dist/index";
//core components

import bckImg2 from "../../assets/Img/BckImghome2.png";
import homeImg3 from "../../assets/Img/homeImg3.png";
import homeImg4 from "../../assets/Img/homeImg4.png";
import homeImg5 from "../../assets/Img/homeImg5.png";
import homeImg6 from "../../assets/Img/homeImg6.png";
import phonehimg from "../../assets/Img/Phonehimg.png";
import homevideo from "../../assets/video/custom.mp4";
import MainDialog from "./RoomDialog/MainDialog";
import playstore from "../../assets/Img/playstore.png";

const Home = () => {
  const vidRef = useRef();

  useEffect(() => {
    // vidRef.current.play();
    // window.scrollTo(0, 0);
  }, []);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleClickOpen = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        setValue(0);
        break;
      case 1:
        setValue(1);
        break;
      case 2:
        setValue(2);
        break;
      case 3:
        setValue(3);
        break;
    }

    setOpen(true);
  };
  const handleClickOpen2 = () => {
    setOpen(true);
  };
  const islg = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Grid container>
        <Grid alignItems="baseline" item xs={12}>
          {/* <ReactPlayer
        value={vidRef}
        width="100%"
        height="100%"
        url={homevideo}
        playing={true}
        controls={true}
        muted={false}
      /> */}
      {/* <video
            width="100%"
            src={homevideo}
            ref={vidRef}
            muted={true}
            autoPlay
            loop
          /> */}
      {/* <video width="100%" controls loop autoplay="autoplay">
            <source src={homevideo} type="video/mp4" />
          </video> */}
      {/* </Grid>
      </Grid> */}

      <Grid container style={{ marginTop: "-4px" }}>
        <Grid item style={{ position: "relative" }} xs={12} sm={12} lg={12}>
          <img src={bckImg2} />
          <Grid item className={islg ? "homeitem1 " : "homeitem2 "}>
            <Button className="homeaboutcynetixbtn whitebutton ">
              <Link to="/about" className="link-style-color">
                {" "}
                About PsiBorg
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: "50px" }}>
        <Grid item xs={12} sm={8} lg={8}>
          <img src={homeImg3} />
        </Grid>
        <Grid item xs={12} sm={4} lg={4} className="gridbckcolr padding-30">
          <Typography
            className={
              islg
                ? "bluecolortypopopins  fs-25px      "
                : "bluecolortypopopins  fs-16pxs   "
            }
          >
            Make your Home,
            <br /> a smart home
          </Typography>
          <Typography
            className={
              islg ? "skytypo  fs-16px pt20-pr60" : "skytypo  fs-11px  p-t20"
            }
          >
            PsiBorg is a curator of Intelligent Home Automation Solutions for
            modern spaces, delivering technology driven luxurious living
            experiences. We convert your house into a smart home by giving
            control to your smartphone and touch panels. You can control the
            lighting, curtains, home theatre, doors, CCTV, music and climate
            control as you desire.  We help you connect your smart devices to
            work at once to enhance your living experience. Our home technology
            services take those little stresses away from your busy life.
            <span
              className={
                islg
                  ? "bluecolortypopopins  fs-16px   "
                  : "bluecolortypopopins  fs-11px     "
              }
            >
              “Turn your home into a sci-fi adventure”{" "}
            </span>
            “Talk to a virtual assistant to get things done in a click”.Turn
            on/off the light while leaving/arriving. Bring home a seamless
            automation system and control everything in the palm of your hand!
          </Typography>
          <Grid container justifyContent="center" className=" p-t30">
            <Button
              className={
                islg
                  ? "whycynetixbtn blackcolortypo "
                  : "whycynetixbtnsm   blackcolortypo"
              }
            >
              Why PsiBorg
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid container sm={12} className="gridbckcolr homecontainer-4">
        <div className="home-div-img-4">
          <img src={homeImg4} />
          <div className="home-div-4-1">
            <MainDialog
              states={{ open, setOpen, value, setValue, index: 0 }}
              buttonName={"Bed Room"}
              handleClickOpen={handleClickOpen}
            />
          </div>
          <div className="home-div-4-2">
            <MainDialog
              states={{ open, setOpen, value, setValue, index: 1 }}
              buttonName={"Office"}
              handleClickOpen={handleClickOpen}
            />
          </div>
          <div className="home-div-4-3">
            <MainDialog
              states={{ open, setOpen, value, setValue, index: 2 }}
              buttonName={"Living Room"}
              handleClickOpen={handleClickOpen}
            />
          </div>
          <div className="home-div-4-4">
            <MainDialog
              states={{ open, setOpen, value, setValue, index: 3 }}
              buttonName={"Kitchen"}
              handleClickOpen={handleClickOpen}
            />
          </div>
        </div>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={8} lg={8} className="gridbckcolr padding-60">
          <Typography align="center" style={{ width: "100%" }}>
            <img src={homeImg5} className={islg ? "width50" : "width-100 "} />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} lg={4} className="padding-30">
          <Typography
            className={
              islg
                ? "bluecolortypopopins  fs-25px"
                : "bluecolortypopopins  fs-16px"
            }
          >
            Switch & Control
          </Typography>
          <Typography
            className={
              islg ? "skytypo  fs-16px pt20-pr60" : "skytypo  fs-11px  p-t20"
            }
          >
            A sleek and precise touch board that works on your fingertips Our
            beautifully designed glass touch panels enhance the living comfort
            and grace to you modern interiors
          </Typography>

          <Button
            className={islg ? "buttonstylep  mt-50 " : "buttonstylepsm mt-20"}
          >
            Products
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={4} lg={4} className="padding-30">
          <Typography
            className={
              islg
                ? "bluecolortypopopins  fs-25px "
                : "bluecolortypopopins  fs-16px p-r60"
            }
          >
            YOUR VOICE WILL ACT AS A COMMAND
          </Typography>
          <Typography
            className={
              islg ? "skytypo  fs-16px pt20-pr60" : "skytypo  fs-11px  p-t20"
            }
          >
            Make your appliances, your Genies Not only can you monitor and
            control PsiBorg Smart Switch using a gentle tap on our smartphone,
            or being physically present near it, but also using your voice. All
            the PsiBorg's Smart App can be integrated with Amazon Alexa as well
            as Google Assistant.
          </Typography>
          <Typography
            className={
              islg ? "skytypo  fs-16px pt20-pr60" : "skytypo  fs-11px  p-t20"
            }
          >
            So, no need to spend the energy of getting up to turning an
            appliance at your place on/off, instead just order them to do so by
            using your voice!
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} lg={8}>
          <img src={homeImg6} />
        </Grid>
      </Grid>
      <Grid container className="p-t60 backGcolor padding-30">
        <Grid item xs={12} sm={5} lg={5}>
          <img src={phonehimg} />
        </Grid>
        <Grid item xs={12} sm={6} lg={6} className="padding-30">
          <Typography
            className={
              islg
                ? "whitecolortypopopins  fs-25px"
                : "whitecolortypopopins  fs-16px"
            }
          >
            Your House, <br /> Your Schedule
          </Typography>
          <Typography
            className={
              islg ? "skytypo  fs-16px pt20-pr60" : "skytypo  fs-11px  p-t20"
            }
          >
            Customize your Home Routines
          </Typography>
          <Typography
            className={
              islg ? "skytypo  fs-16px pt20-pr60" : "skytypo  fs-11px  p-t20"
            }
          >
            Live in the future, control everything at your fingertips, run
            customized schedules, take control of your home and make it do
            things for you. Give our services a chance and you shall never go
            back to the ordinary.
          </Typography>
          <Typography
            className={
              islg
                ? "whitecolortypo  fs-16px pt20-pr60"
                : "whitecolortypo  fs-11px  p-t20"
            }
          >
            Fret not about forgetting to switch off the lights before leaving
            your place because the control is now in your hands. Pre-plan the
            switching on and off of the lights and various devices after leaving
            and before reaching your home sweet home.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
