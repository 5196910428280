import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Input,
  TextareaAutosize,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Wchome from "../../assets/Img/WChome.png";
import whyhandmobile from "../../assets/Img/WCHandMobile.png";
import benefeature from "../../assets/Img/BenefeaturesImg.png";
import phonehimg from "../../assets/Img/Phonehimg.png";
import location from "../../assets/Img/locationImg.png";

const Home = () => {
  const theme = useTheme();

  const LocImageSM = useMediaQuery(theme.breakpoints.down("sm"));
  const dynamicLocImageSM = {
    ...(LocImageSM && { display: "none" }),
  };

  const islg = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Grid
        container
        style={{
          backgroundColor: "rgba(5, 37, 76, 1)",
          borderTop: "1px solid white",
          paddingBottom: "80px",
        }}
      >
        <Grid item xs={12}>
          <Typography
            className={
              islg
                ? "whitecolortypo2  fs-32px mt-60"
                : "whitecolortypo2  fs-25px mt-60"
            }
            align="center"
          >
            Why PsiBorg
          </Typography>
          <Typography
            className={
              islg
                ? "whitecolortypo2  fs-25px mt-20 "
                : "whitecolortypo2  fs-16px mt-20 "
            }
            align="center"
          >
            Because you want to have the best home automation experience!
          </Typography>
          <Grid item className="width-70">
            <Typography align="center" className="mt-50">
              <img src={Wchome} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="padding-30">
        <Grid item sm={12} md={4} lg={4}>
          <Typography
            className={
              islg
                ? "bluecolortypopopins  fs-16px "
                : "bluecolortypopopins  fs-11px "
            }
          >
            ACCESSIBILITY TO CONTROL FROM ANYWHERE
          </Typography>
          <Typography
            className={
              islg
                ? "bluecolortypopopins  fs-25px  p-t10"
                : "bluecolortypopopins  fs-16px  p-t10"
            }
          >
            The control is completely <br /> in your hands
          </Typography>
          <Typography
            className={
              islg ? "skytypo  fs-16px pt20-pr60" : "skytypo  fs-11px  p-t20"
            }
          >
            A smart home operating system virtually connects all of the
            technology in your home. With a smartphone, you and your family can
            control nearly everything in the house with simple clicks and taps.
            Our easy and reliable solutions make your home fully automated and
            your lifestyle impressive.
          </Typography>
        </Grid>
        <Grid
          item
          sm={12}
          md={8}
          lg={8}
          className={islg ? "  mt--90  " : "mt-30  "}
        >
          <img src={whyhandmobile} />
        </Grid>
      </Grid>
      <Grid container className="bcheader">
        <Typography
          className="whitecolortypo2 fs-25px  width-100"
          align="center"
        >
          Benefits & Features of PsiBorg
        </Typography>
      </Grid>
      {/* <Grid item>
        <img src={benefeature} />
      </Grid> */}
      <Grid container className="p-t60 backGcolor ">
        <Grid item sm={12} md={6} lg={6}>
          <img src={phonehimg} />
        </Grid>
        <Grid item xs={12} sm={6} lg={6} className="padding-30">
          <Typography
            className={
              islg
                ? "whitecolortypopopins  fs-25px"
                : "whitecolortypopopins  fs-16px"
            }
          >
            Your House, <br /> Your Schedule
          </Typography>
          <Typography className="skytypo700 p-t10 p-b10">
            Customize your Home Routines
          </Typography>
          <Typography
            className={
              islg
                ? "skytypo700  fs-16px pt20-pr60"
                : "skytypo700  fs-11px  p-t20"
            }
          >
            With numerous options in the market, choosing one can be a little
            overwhelming. This is why we offer a fully personalized and
            automated smart home system. Our user-friendly interface makes sure
            you have your home just the way you like it.
          </Typography>
          <Typography
            className={
              islg
                ? "whitecolortypopopins  fs-25px p-t60"
                : "whitecolortypopopins  fs-16px p-t60"
            }
          >
            Movie Time - {`>`} Turn off lights
          </Typography>
          <Typography
            className={
              islg
                ? "whitecolortypopopins  fs-25px p-t5"
                : "whitecolortypopopins  fs-16px p-t5"
            }
          >
            Office hours - {`>`} Turn off Appliances till 9-5pm.
          </Typography>
        </Grid>
      </Grid>
      <Grid container className="backGcolor">
        <Grid container className="p-l30">
          <Grid item sm={12} lg={3} md={3} className="p-t40 backGcolor">
            <Typography
              className={
                islg ? "skybluetypo700  fs-32px" : "skybluetypo700  fs-25px "
              }
            >
              Contact Us
            </Typography>

            <Input
              placeholder="Enter your Name"
              disableUnderline
              className="inputstyle"
            />
            <Input
              placeholder="Enter your Email"
              disableUnderline
              className="inputstyle"
            />
            <TextareaAutosize
              className="textarea"
              aria-label="minimum height"
              minRows={3}
              disableUnderline
              placeholder="Enter your Requirement"
            />
            <Button className="wcsubmitButton">Submit</Button>
          </Grid>
          <Grid
            item
            sm={12}
            lg={3}
            md={3}
            className="wccallusgrid padding-30  m30-0 "
          >
            <Grid item>
              <Typography
                className="whitecolortypo1"
                style={{ fontSize: "20px" }}
              >
                Address
              </Typography>
              <Typography className="bluecolortypo p-t10">
                India Office: A- 57, Fifth Floor, Sector 136 Noida,
                <Typography className="bluecolortypo">UP- 201304</Typography>
                <Typography className="bluecolortypo">India</Typography>
              </Typography>
            </Grid>
            <Grid item className="">
              <Typography
                className="whitecolortypo1 p-t30"
                style={{ fontSize: "20px" }}
              >
                Call us
              </Typography>
              <Typography className="bluecolortypo p-t10">
                +91-9313785560
                {/* <Typography className="bluecolortypo">+91-989898989</Typography> */}
                {/* <Typography className="bluecolortypo">+91-989898989</Typography> */}
              </Typography>
            </Grid>
            {/* <Grid item className=" p-t30">
              <Typography
                className="whitecolortypo1"
                style={{ fontSize: "20px" }}
              >
                Opening Hours
              </Typography>
              <Typography className="bluecolortypo p-t10">
                Loreum ipsum loreum ipsum
                <Typography className="bluecolortypo">
                  Loreum ipsum loreum ipsum
                </Typography>
              </Typography>
            </Grid> */}
          </Grid>
          <Grid
            item
            sm={false}
            lg={6}
            md={6}
            sx={{
              ...dynamicLocImageSM,
              paddingTop: "40px",
              backgroundColor: "#ffffff",
            }}
          >
            <Typography align="center">
              <img src={location} style={{ height: "600px", width: "600px" }} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Home;
