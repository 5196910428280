import React, { useState, useEffect } from "react";
import { Grid, Typography, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
//core component
import homeimg1 from "../../assets/Img/homeImg1.png";
import SmartTSText from "../../assets/Img/SmartTSText.png";
import clogo from "../../assets/Img/final1.png";
import grouppanel from "../../assets/Img/grouppanel.png";
import touchpanel1 from "../../assets/Img/touchpanel-1.png";
import touchpanel2 from "../../assets/Img/touchpanel-2.png";
import touchpanel3 from "../../assets/Img/touchpanel-3.png";
import touchpanel4 from "../../assets/Img/touchpanel-4.png";

import board from "../../assets/Img/board.png";
import productpanel from "../../assets/Img/productpanel.png";
import productpanel1 from "../../assets/Img/productpanel1.png";
import productpanel2 from "../../assets/Img/productpanel2.png";
import productpanel3 from "../../assets/Img/productpanel3.png";
import productpanel4 from "../../assets/Img/productpanel4.png";
import productpanel5 from "../../assets/Img/productpanel5.png";

const Home = () => {
  const theme = useTheme();
  const islg = useMediaQuery(theme.breakpoints.up("md"));
  const [venueImg, setVenueImg] = useState([
    {
      img: touchpanel3,
    },
    {
      img: touchpanel4,
    },
    {
      img: touchpanel1,
    },
    {
      img: touchpanel2,
    },
  ]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Grid container>
        <Grid item sm={12} className="whycynetixgrid width-70">
          <Typography
            align="center"
            className={
              islg
                ? "bluecolortypopopins  fs-25px  mt-60 ls-6"
                : "bluecolortypopopins  fs-16px  mt-60 ls-6"
            }
          >
            Smart Touch Panels
          </Typography>
          <Typography
            className={
              islg
                ? "bluecolortypopopins  fs-22px  mt-20 ls-2 "
                : "bluecolortypopopins  fs-16px  mt-20 ls-2 "
            }
            variant="h5"
            align="center"
          >
            We offer you products that give you full control of almost
            everything at home—wherever you are
          </Typography>
          <Grid item className="width-50 mt-30">
            <Typography align="center" className="width-100">
              {/* <img src={clogo} className="width-30" /> */}
            </Typography>
          </Grid>
          <Container maxWidth="xl">
            <Typography align="center" sx={{ marginBottom: "80px" }}>
              <Swiper
                slidesPerView={2}
                spaceBetween={100}
                slidesPerGroup={2}
                loop={false}
                loopFillGroupWithBlank={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
              >
                {venueImg &&
                  venueImg.map((item, index) => (
                    <>
                      <SwiperSlide key={index}>
                        <img
                          src={item.img}
                          className="mt-50 mb-20"
                          style={{
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                          }}
                        />
                      </SwiperSlide>
                    </>
                  ))}
              </Swiper>
            </Typography>
          </Container>
        </Grid>
        <Grid container>
          <Grid item sm={12} md={4} lg={4} className="padding-30">
            <Typography
              className={
                islg
                  ? "bluecolortypopopins  fs-25px"
                  : "bluecolortypopopins  fs-16px"
              }
            >
              Glass Panel Touch <br /> Switches
            </Typography>
            <Typography
              className={
                islg ? "skytypo  fs-16px pt20-pr60" : "skytypo  fs-11px  p-t20"
              }
            >
              . Just one smooth touch is enough to control the temperature,
              lighting and appeal of the room. The advanced circuit design
              prevents any electrical load. These easy to install and operate
              switches would make your home automation game stronger.
            </Typography>
          </Grid>

          <Grid item sm={12} lg={8} md={8}>
            <img src={board} />
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            backgroundColor: "#05254C",
            padding: "100px",
          }}
        >
          <Grid item sm={12} lg={7} md={7}>
            <Grid item sm={11} lg={11} md={11}>
              <img
                src={productpanel}
                style={{
                  height: "300px",
                  width: "100%",
                }}
              />
            </Grid>
          </Grid>
          <Grid item sm={12} md={5} lg={5}>
            <Typography
              className={
                islg
                  ? "whitecolortypopopins  fs-25px p-t60"
                  : "whitecolortypopopins  fs-16px p-t60"
              }
            >
              No more switches, just
              <br /> a simple touch
            </Typography>
            <Typography
              className={
                islg
                  ? "whitecolortypopopins  fs-25px  p-t60"
                  : "whitecolortypopopins  fs-16px  p-t60"
              }
            >
              Elegant Design Crystal Glass Panel Add aesthetics to functionality
            </Typography>
          </Grid>
        </Grid>
        <Typography
          align="center"
          className={
            islg
              ? "bluecolortypo  fs-25px mt-30 width-100"
              : "bluecolortypo  fs-16px mt-30 width-100"
          }
        >
          Explore our impressive range of products
        </Typography>
        <Typography
          align="center"
          className={
            islg
              ? "bluecolortypopopins  fs-18px  width-100"
              : "bluecolortypopopins  fs-11px  width-88"
          }
        >
          Convert your home into a Smart Home, which makes every switch and
          appliance in your home a connected device.
        </Typography>
        <Grid
          container
          sx={{ marginLeft: "auto", marignRight: "auto", width: "88%" }}
        >
          <Grid item xs={12} md={6} lg={4}>
            <Grid
              item
              className={islg ? "productpanelgrid  " : "productpanelgridsm "}
            >
              <Typography align="center">
                <img
                  src={productpanel}
                  style={{ height: "150px" }}
                  className="productimg1"
                />
              </Typography>
              <Typography
                align="center"
                className={
                  islg
                    ? "bluecolortypo  fs-20px  width-100 pt10"
                    : "bluecolortypo  fs-13px  width-100 pt10"
                }
              >
                6 Switch 1 Fan regulator
              </Typography>
              <Typography
                align="center"
                className={
                  islg
                    ? "bluecolortypo fs-20px  width-100"
                    : "bluecolortypo fs-13px  width-100"
                }
              >
                Get a Quote*
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={12} md={6} lg={4}>
            <Grid
              item
              className={islg ? "productpanelgrid  " : "productpanelgridsm "}
            >
              <Typography
                align="center"
                className={
                  islg
                    ? "bluecolortypo  fs-25px  width-100"
                    : "bluecolortypo  fs-13px  width-100"
                }
              >
                <img src={productpanel1} className="roductimg2" />
              </Typography>
              <Typography
                sx={{ paddingTop: "30px" }}
                align="center"
                className={
                  islg
                    ? "bluecolortypo  fs-20px  width-100"
                    : "bluecolortypo  fs-13px width-100"
                }
              >
                Glass Touch 4 Switch
              </Typography>
              <Typography
                align="center"
                className={
                  islg
                    ? "bluecolortypo fs-20px  width-100"
                    : "bluecolortypo  fs-13px  width-100"
                }
              >
                Get a Quote*
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Grid
              item
              className={islg ? "productpanelgrid  " : "productpanelgridsm "}
            >
              <Typography
                align="center"
                className={
                  islg
                    ? "bluecolortypo  fs-25px  width-100"
                    : "bluecolortypo  fs-13px width-100"
                }
              >
                <img src={productpanel2} className="productimg3" />
              </Typography>
              <Typography
                sx={{ paddingTop: "30px" }}
                align="center"
                className={
                  islg
                    ? "bluecolortypo  fs-20px  width-100"
                    : "bluecolortypo  fs-13px  width-100"
                }
              >
                Glass Touch Switch
              </Typography>
              <Typography
                align="center"
                className={
                  islg
                    ? "bluecolortypo  fs-20px  width-100"
                    : "bluecolortypo  fs-13px  width-100"
                }
              >
                Get a Quote*
              </Typography>
            </Grid>
          </Grid>{" "}
        </Grid>

        <Typography
          align="center"
          className={
            islg
              ? "bluecolortypopopins  fs-25px  width-100 mt-10px"
              : "bluecolortypopopins  fs-16px  width-100  mt-10px"
          }
        >
          Convert your home into smart home using
        </Typography>

        <Grid
          container
          sx={{ marginLeft: "auto", marignRight: "auto", width: "88%" }}
        >
          <Grid item xs={12} md={6} lg={4}>
            <Grid
              item
              className={islg ? "productpanelgrid  " : "productpanelgridsm "}
            >
              <Typography
                align="center"
                className={
                  islg
                    ? "bluecolortypo  fs-25px  width-100"
                    : "bluecolortypo  fs-13px  width-100"
                }
              >
                <img src={productpanel3} className="productimg" />
              </Typography>
              <Typography
                sx={{ paddingTop: "30px" }}
                align="center"
                className={
                  islg
                    ? "bluecolortypo fs-20px  width-100"
                    : "bluecolortypo  fs-13px  width-100"
                }
              >
                Touch Panel
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Grid
              item
              className={islg ? "productpanelgrid  " : "productpanelgridsm "}
            >
              <Typography
                align="center"
                className={
                  islg
                    ? "bluecolortypo  fs-25px  width-100"
                    : "bluecolortypo  fs-13px  width-100"
                }
              >
                <img src={productpanel4} className="productimg5" />
              </Typography>
              <Typography
                sx={{ paddingTop: "30px" }}
                align="center"
                className={
                  islg
                    ? "bluecolortypo  fs-20px  width-100"
                    : "bluecolortypo  fs-13px width-100"
                }
              >
                Smart Home App
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Grid
              item
              className={islg ? "productpanelgrid  " : "productpanelgridsm "}
            >
              <Typography
                align="center"
                className={
                  islg
                    ? "bluecolortypo  fs-25px  width-100"
                    : "bluecolortypo  fs-13px  width-100"
                }
              >
                <img src={productpanel5} className="productimg6" />
              </Typography>
              <Typography
                sx={{ paddingTop: "30px" }}
                align="center"
                className={
                  islg
                    ? "bluecolortypo  fs-20px  width-100"
                    : "bluecolortypo  fs-13px  width-100"
                }
              >
                Voice Assistant With Alexa
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
